import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { createContext, useContext, useEffect, useState } from 'react';
import FullscreenLoader from '../components/FullscreenLoader/FullscreenLoader';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import axios from 'axios';

const ListingContext = createContext();

export const ListingsProvider = ({ children }) => {
    const [listings, setListings] = useState(null);
    const [loading, setLoading] = useState(true);

    const getListings = async () => {
        try {
            const result = await axios.post("https://getlistings-qb4ml4wgjq-uc.a.run.app")
            setListings(result.data)
            setLoading(false)
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    }

    useEffect(() => {
        getListings()

    }, [])

    return (
        <ListingContext.Provider value={{ listings, loading }}>
            {children}
        </ListingContext.Provider>
    )
}

export { ListingContext }