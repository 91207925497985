import React, { useContext, useState } from 'react';
import { AuthContext, AuthProvider } from '../../firebase/AuthContext';
import { Link } from 'react-router-dom';
import { Box, Button, CircularProgress, Divider, Drawer, Dropdown, IconButton, List, ListItem, ListItemButton, Menu, MenuButton, MenuItem, ModalClose, Stack, Tooltip, Typography, Link as MUILink } from '@mui/joy';
import { DragHandle, Logout, Person } from '@mui/icons-material';
import { GoogleAuthProvider, getAuth, signInWithPopup, signOut } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { toast } from 'react-toastify';
import { getAnalytics, logEvent } from 'firebase/analytics';
import logo from '../../assets/favicon.png';

const Navbar = () => {

    const auth = getAuth()
    const analytics = getAnalytics()
    const functions = getFunctions()
    const provider = new GoogleAuthProvider();

    const [authloading, setLoading] = useState(false);

    const loginWithGoogle = async () => {
        setLoading(true)
        const createUserDoc = httpsCallable(functions, "createNewUser")
        try {
            const userCred = await signInWithPopup(auth, provider);
            const resp = await createUserDoc({
                uid: userCred.user.uid,
                email: userCred.user.email,
                displayName: userCred.user.displayName
            })
            console.log(resp.data)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.error(e);
        }
    }

    const { user, listing, loading } = useContext(AuthContext);
    const [open, setOpen] = useState(false);

    const toggleDrawer = (inOpen) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(inOpen);
    };

    return (
        <>
            <AuthProvider>
                <header>
                    <div className="container">
                        <nav>
                            <Stack direction={"row"} alignItems={'center'} justifyContent='start'>
                                <Link to="/" style={{ textDecoration: 'none', color: 'black', fontWeight: 600, marginRight: '20px' }}><Typography level='title-md' startDecorator={<img src={logo} width={30} height={30} />}>US Devs For Hire</Typography></Link>
                                <Stack direction="row" alignItems="center" gap={2} sx={{ display: { xs: 'none', md: 'block' } }}>
                                    <Link to="/developers" style={{ textDecoration: 'none', color: 'black' }} onClick={() => logEvent(analytics, "used_link_on_navbar")}><Button color='neutral' variant='outlined'>Find your developer</Button></Link>
                                    <Link to="/support" style={{ textDecoration: 'none', color: 'black' }} onClick={() => logEvent(analytics, "used_support_link")}><Button color='neutral' variant='plain'>Contact support</Button></Link>
                                </Stack>
                            </Stack>

                            <Stack direction='row' gap={1}>

                                {loading ?
                                    <CircularProgress determinate={false} size='sm' color='neutral' />
                                    : <>
                                        {user
                                            ? <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                                <Tooltip title='View your profile' variant='soft' color='neutral'><Link to='/your-profile' style={{ textDecoration: 'none' }}><Button variant='outlined' color='neutral' startDecorator={<img style={{ width: '25px', height: '25px', borderRadius: "50px" }} src={user.photoURL} />} onClick={() => logEvent(analytics, 'used_profile_link')}>Your profile</Button></Link></Tooltip>
                                            </Box>
                                            : <>
                                                <Box sx={{ display: { xs: 'none', md: 'block' } }}><Button variant='outlined' color='main' loading={authloading} onClick={loginWithGoogle}>Log in</Button></Box>
                                                <Box sx={{ display: { xs: 'none', md: 'block' } }}><Link to='/apply-as-a-developer' style={{ textDecoration: 'none', color: 'black', fontWeight: 600 }}><Button color='main' variant='solid'>Apply as a developer</Button></Link></Box>
                                            </>}
                                    </>}
                            </Stack>

                            <IconButton sx={{ display: { xs: 'block', md: 'none' } }} variant='plain' color='neutral' onClick={toggleDrawer(true)}><DragHandle /></IconButton>
                        </nav>
                    </div>
                </header>

                <Drawer open={open} onClose={toggleDrawer(false)} anchor='top'>
                    <ModalClose />
                    <Box
                        role='presentation'
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                        padding={3}>
                        <List>
                            <Typography level='body-xs'>EXPLORE</Typography>
                            <ListItemButton><Link onClick={() => logEvent(analytics, "used_link_on_navbar")} to="/developers" style={{ textDecoration: 'none', color: 'black' }}><div>Find your devs</div></Link></ListItemButton>
                            <Divider sx={{ margin: '15px 0' }} />
                            <Typography level='body-xs'>ACCOUNT</Typography>
                            <ListItemButton><Link onClick={() => logEvent(analytics, "used_support_link")} to="/support" style={{ textDecoration: 'none', color: 'black' }}><div>Contact support</div></Link></ListItemButton>
                            {!user ? <Link style={{ textDecoration: 'none', color: 'white' }} to='/apply-as-a-developer' reloadDocument><ListItemButton variant='solid' color='main' sx={{ height: '45px', mt: 0.5 }}>Apply as a developer</ListItemButton></Link> : null}
                            {!user ? <ListItemButton loading={authloading} onClick={loginWithGoogle} variant='outlined' color='main' sx={{ height: '45px', mt: 0.5 }}>Log in</ListItemButton> : null}
                            {user ? <ListItemButton color='plain' variant='outlined'><Link style={{ textDecoration: 'none' }} to='/your-profile' onClick={() => logEvent(analytics, 'used_profile_link')}>Your profile</Link></ListItemButton> : null}
                        </List>
                    </Box>
                </Drawer>
            </AuthProvider>
        </>
    )
}

export default Navbar;