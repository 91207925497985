const regions = [
    "Alabama, USA",
    "Alaska, USA",
    "Arizona, USA",
    "Arkansas, USA",
    "California, USA",
    "Colorado, USA",
    "Connecticut, USA",
    "Delaware, USA",
    "Florida, USA",
    "Georgia, USA",
    "Hawaii, USA",
    "Idaho, USA",
    "Illinois, USA",
    "Indiana, USA",
    "Iowa, USA",
    "Kansas, USA",
    "Kentucky, USA",
    "Louisiana, USA",
    "Maine, USA",
    "Maryland, USA",
    "Massachusetts, USA",
    "Michigan, USA",
    "Minnesota, USA",
    "Mississippi, USA",
    "Missouri, USA",
    "Montana, USA",
    "Nebraska, USA",
    "Nevada, USA",
    "New Hampshire, USA",
    "New Jersey, USA",
    "New Mexico, USA",
    "New York, USA",
    "North Carolina, USA",
    "North Dakota, USA",
    "Ohio, USA",
    "Oklahoma, USA",
    "Oregon, USA",
    "Pennsylvania, USA",
    "Rhode Island, USA",
    "South Carolina, USA",
    "South Dakota, USA",
    "Tennessee, USA",
    "Texas, USA",
    "Utah, USA",
    "Vermont, USA",
    "Virginia, USA",
    "Washington, USA",
    "West Virginia, USA",
    "Wisconsin, USA",
    "Wyoming, USA",
    "Alberta, Canada",
    "British Columbia, Canada",
    "Manitoba, Canada",
    "New Brunswick, Canada",
    "Newfoundland and Labrador, Canada",
    "Nova Scotia, Canada",
    "Ontario, Canada",
    "Prince Edward Island, Canada",
    "Quebec, Canada",
    "Saskatchewan, Canada",
    "Northwest Territories, Canada",
    "Nunavut, Canada",
    "Yukon, Canada",
];

export { regions };
