import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { Alert, Button, Chip, CircularProgress, FormControl, FormHelperText, Grid, Input, Modal, ModalClose, ModalDialog, Sheet, Skeleton, Stack, Typography } from '@mui/joy';
import { AuthContext } from '../../firebase/AuthContext';
import axios from 'axios';
import { Feedback, Info } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { Helmet } from 'react-helmet';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { UserDataContext } from '../../firebase/UserDataProvider';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
var _ = require('lodash');
const dayjs = require('dayjs');

const YourProfile = () => {

    const [infoModalOpen, setInfoModalOpen] = useState(false);
    const [warnModalOpen, setWarnModalOpen] = useState(false);
    const [usernameModalOpen, setUsernameModalOpen] = useState(false)

    const [username, setUsername] = useState("")
    const [settingUsername, setSettingUsername] = useState(false)

    // State variables for username input
    const [startedTyping, setStartedTyping] = useState(false)
    const [available, setAvailable] = useState(false)
    const [usernameLoading, setUsernameLoading] = useState(false)

    // Data context 
    const { user, profile, userDataLoading, refreshUserData } = useContext(UserDataContext)
    const auth = getAuth()
    const analytics = getAnalytics()
    const functions = getFunctions()
    const db = getFirestore()

    const checkUsernameAvailability = _.debounce(async (username) => {
        if (username === "") { return }
        setStartedTyping(true)
        setUsernameLoading(true)
        const userdoc = await getDoc(doc(db, "listings", username))
        if (userdoc.exists()) {
            setAvailable(false)
        } else {
            setAvailable(true)
            setUsername(username)
        }
        setUsernameLoading(false)
        console.log("Checking availability for", username)
    }, 1000)

    const saveUsername = async () => {
        if (username === "") { return }
        setSettingUsername(true)
        const setProfileUsername = httpsCallable(functions, "setProfileUsername")
        try {
            await setProfileUsername({ uid: user.uid, username })
            refreshUserData()
            setUsernameModalOpen(false)
        } catch (error) {
            console.log(error)
            // TODO: Add a toast function
        }
    }

    return (
        <>
            <Helmet>
                <title>Your profile</title>
            </Helmet>

            <Navbar />

            {/* Modal to change username */}
            <Modal open={usernameModalOpen}>
                <ModalDialog sx={{ maxWidth: '500px', width: '100%' }}>
                    <Sheet sx={{ p: 2 }}>
                        <Typography level='title-md'>Change your username</Typography>
                        <Typography level='body-sm'>Set a new username for your profile here.</Typography>
                        <FormControl>
                            <Input
                                defaultValue={profile?.username}
                                disabled={settingUsername}
                                sx={{ mt: 1 }}
                                onChange={(e) => checkUsernameAvailability(e.target.value)}
                                autoFocus
                                name='username'
                                variant='soft'
                                placeholder="Enter a username"
                                endDecorator={usernameLoading && <CircularProgress determinate={false} size='sm' color='neutral' />} />
                            {startedTyping
                                ? <>
                                    {usernameLoading
                                        ? null
                                        : <>
                                            {available
                                                ? <FormHelperText><Typography level='body-sm' color='success'>Username is available!</Typography></FormHelperText>
                                                : <FormHelperText><Typography level='body-sm' color='warning'>That username is taken, please try another one.</Typography></FormHelperText>}
                                        </>}
                                </>
                                : null}
                        </FormControl>
                        <Stack mt={1} direction='row' justifyContent='flex-end'><Button loading={settingUsername} disabled={!available} color='main' onClick={saveUsername}>Save username</Button></Stack>
                    </Sheet>
                </ModalDialog>
            </Modal>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={infoModalOpen}
                onClose={() => setInfoModalOpen(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}>
                    <ModalClose />
                    <Typography
                        id="modal-title"
                        level="h4"
                        fontWeight="lg"
                        mb={1}
                    >
                        Your profile is awaiting approval
                    </Typography>
                    <Typography id="modal-desc">
                        We are onboarding new developers one by one as we build this platform. Your profile will be active once approved.
                    </Typography>
                </Sheet>
            </Modal>

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={warnModalOpen}
                onClose={() => setWarnModalOpen(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Sheet
                    variant="soft"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                    color='warning'>
                    <ModalClose />
                    <Typography
                        id="modal-title"
                        level="h4"
                        fontWeight="lg"
                        mb={1}
                    >
                        Your profile is being reviewed
                    </Typography>
                    <Typography id="modal-desc">
                        We noticed some issues with your profile and will be getting in touch soon. Once the issues are resolved, your profile will be activated!
                    </Typography>
                </Sheet>
            </Modal>

            <div className="mt-5">
                <div className="container">
                    <Grid container spacing={4}>
                        <Grid xs={12} md={6}>
                            <Typography level='h2' endDecorator={<>

                                {!profile?.publicProfile ? <Chip size='md' color='neutral'>Inactive</Chip> : <>
                                    {profile?.publicProfile.metadata.status == "Active" ? <Chip size='md' color='success'>Active</Chip> : null}
                                    {profile?.publicProfile.metadata.status == "Payment pending" ? <Chip size='md' color='neutral'>Waiting for payment</Chip> : null}
                                    {profile?.publicProfile.metadata.status == "Awaiting approval" ? <Chip size='md' color='neutral' onClick={() => setInfoModalOpen(true)} startDecorator={<Info fontSize='md' />}>Awaiting approval</Chip> : null}
                                    {profile?.publicProfile.metadata.status == "Under review" ? <Chip size='md' color='warning' onClick={() => setWarnModalOpen(true)} startDecorator={<Info fontSize='md' />}>Under review</Chip> : null}
                                </>}
                            </>}>Your public profile</Typography>
                            {profile?.publicProfile && <Typography level='title-lg'>Your username: {profile?.publicProfile.username}</Typography>}
                            {(profile?.publicProfile && profile?.publicProfile.metadata.status == "Awaiting approval") && <Typography levle='body-sm'>Your profile has been submitted for review and will be available online once it's approved and the payment is done.</Typography>}
                            {userDataLoading
                                ? <>
                                    <CircularProgress
                                        size='sm'
                                        color='neutral'
                                        determinate={false} />
                                </>
                                : <>
                                    {(profile?.publicProfile && profile?.publicProfile.metadata.status === "Active") ?
                                        <>
                                            <Typography level="body-sm">This is how your profile is shown on the listing page. <Link target='__blank' rel='noopener' onClick={() => logEvent(analytics, "previewed_profile")} to={`/profile/${profile?.username}`}>Preview it here</Link></Typography>
                                            <Sheet variant='outlined' sx={{ p: 1.5, borderRadius: '5px', mt: 1.5 }}>
                                                <Stack direction='row' justifyContent='space-between' alignItems='start' gap={1}>
                                                    <Stack justifyContent='left' sx={{ textAlign: 'left' }}>
                                                        <Typography level='h4'>{profile?.publicProfile.title}</Typography>
                                                        <Typography level='body-md'>{profile?.publicProfile.location}</Typography>
                                                    </Stack>
                                                    <Typography level='h3'>{`$${profile?.publicProfile.hourlyRate}/hr`}</Typography>
                                                </Stack>
                                                <Stack gap={1} direction='row' flexWrap='wrap' sx={{ mt: 0.5 }}>
                                                    {profile?.publicProfile.skills.map((skill, index) => {
                                                        return <Chip key={index} variant='soft' color='warning' size='md'>{skill}</Chip>
                                                    })}
                                                </Stack>
                                            </Sheet>

                                            <div className="mt-3">
                                                <Typography level='title-md'>Profile actions</Typography>
                                                <Stack mt={1} direction='row' gap={1}>
                                                    <Link to={`/edit-profile`}><Button variant='solid' color='main' onClick={() => logEvent(analytics, "edited_profile")}>Edit profile</Button></Link>
                                                    <Button variant='outlined' color='main' onClick={() => {logEvent(analytics, "changed_username"); setUsernameModalOpen(true)}}>Change username</Button>
                                                </Stack>
                                            </div>
                                        </>
                                        :
                                        null}

                                    {(profile?.publicProfile && profile?.publicProfile.metadata.status === "Payment pending")
                                        ? <>
                                            <Typography level='body-sm'>Your profile has been submitted and will be visible online once the payment is completed and approved.</Typography>
                                            <Alert sx={{ marginTop: '20px' }} variant='soft' color='neutral' startDecorator={<Info />} endDecorator={<a href='https://paypal.me/helloitsamith' target='__blank' rel='noopener'><Button size='sm' color='neutral'>Pay now!</Button></a>}>Use the button to pay for your profile</Alert>
                                        </>
                                        : null}

                                    {!profile?.publicProfile
                                        && <>
                                            <Typography level='body-sm'>Let's create your profile and get you set up!.</Typography>
                                            <a href='/create-profile'><Button size='sm' variant='outlined' sx={{ mt: 1 }} color='neutral'>Create your profile</Button></a>
                                        </>}
                                </>}
                        </Grid>

                        <Grid xs={12} md={6}>
                            <Typography level='h2'>Your account</Typography>
                            <Typography level='body-lg' sx={{ position: 'relative', overflow: 'hidden' }}><Skeleton loading={userDataLoading} />You're signed in as {user?.email}</Typography>
                            <Button sx={{ marginTop: '8px' }} variant='soft' color='danger' loading={userDataLoading} onClick={() => signOut(auth)}>Sign out</Button>

                            {profile?.publicProfile
                                ? <div className="mt-4">
                                    <Typography level='title-lg'>Validity</Typography>
                                    <Typography level='body-md' sx={{ position: 'relative', overflow: 'hidden' }}><Skeleton loading={userDataLoading} />Your profile is valid until <span style={{ fontWeight: 600 }}>{dayjs(profile?.publicProfile.metadata.expiresOn).format("MMM D, YYYY")}</span></Typography>
                                </div>
                                : null}

                            <Typography mt={2} level='title-lg'>Support</Typography>
                            <Link to='/support' target='__blank' rel='noopener'><Button sx={{ mt: 1 }} startDecorator={<Feedback />} color='primary'>Contact support</Button></Link>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    )
}

export default YourProfile;