import React, { useContext, useState, useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { Autocomplete, Chip, CircularProgress, Input, Stack, Typography } from '@mui/joy';
import { Search } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { ListingContext } from '../../firebase/ListingsContext';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import ProfileCard from '../../ui/ProfileCard';
import { regions } from '../../data-types/USStates';
import Footer from '../../ui/Footer';

const Profiles = () => {

    const [searchValue, setSearchValue] = useState(null);
    const [location, setLocation] = useState([])
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true)
    const [listings, setListings] = useState(null)


    const getData = async () => {
        const response = await axios.get("https://getlistings-qb4ml4wgjq-uc.a.run.app")
        setListings(response.data.listings.filter(listing => (listing.publicProfile && listing.publicProfile?.metadata.status === "Active")))
        setList(response.data.listings.filter(listing => (listing.publicProfile && listing.publicProfile?.metadata.status === "Active")));
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if(location.length !== 0) {
            setList(listings.filter(listing => location.includes(listing.publicProfile.location)))
        } else {
            setList(listings)
        }
    }, [location])

    const updateList = (e) => {
        // console.log("from updatelist: ", e.target.value);
        if (e.target.value !== "") {
            setList(listings.filter(listing => (listing.publicProfile.title.toLowerCase().includes(e.target.value.toLowerCase()) || listing.publicProfile.skills.includes(e.target.value.toLowerCase()))))
        } else {
            setList(listings)
        }
    }

    return (
        <>
            <Helmet>
                <title>Find developers for your task</title>
            </Helmet>
            <Navbar />
            <section className="listings" style={{ textAlign: 'center' }}>
                <div className="container">
                    <Typography level='h1' sx={{mt: 4, maxWidth: '700px', ml: 'auto', mr: 'auto'}}>Find a US-based developer for your next project</Typography>
                    <Typography mb={2} level='body-md' sx={{maxWidth: '450px', ml: 'auto', mr: 'auto'}}>Browse through our curated list of US and Canada-based developers ready to take on your next project</Typography>

                    <Input
                        className='mb-4'
                        sx={({
                            maxWidth: '700px',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        })}
                        placeholder="Search for skills, roles etc."
                        size="lg"
                        variant="soft"
                        startDecorator={<Search />}
                        onChange={updateList}
                    />

                    <Autocomplete
                        multiple
                        options={regions}
                        limitTags={2}
                        placeholder='Filter by location'
                        onChange={(e, newValue) => setLocation(newValue)}
                        sx={{ maxWidth: '400px', width: '100%', m: 'auto', mb: 3 }} />

                    {loading
                        ? <CircularProgress determinate={false} size='md' color='neutral' />
                        : <>
                            <Stack gap={2} sx={{ maxWidth: '700px', mx: 'auto' }} >
                                <Typography level='body-xs'>Showing {list.length} profiles.</Typography>

                                {list ? list.map((listing, index) => {
                                    return (
                                        <ProfileCard profile={listing} index={index} />
                                    )
                                }) : null}
                            </Stack>
                        </>}
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Profiles;