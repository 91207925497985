import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { Link, Typography } from '@mui/joy';
import { Helmet } from 'react-helmet';
import Footer from '../../ui/Footer';

const TermsAndConditions = () => {
    return (
        <>
            <Helmet>
                <title>US Devs For Hire - Terms and Conditions</title>
            </Helmet>
            <Navbar />
            <div className="container">
                <div className="my-5">
                    <Typography level='h2'>Terms &amp; Conditions</Typography>
                    <Typography level='h4'>Last updated on January 8, 2024</Typography>

                    <div className="mt-3">
                        <Typography level='body-md'>For the purpose of these Terms and Conditions, The term "we", "us", "our" used anywhere on this page shall mean Ceranova Concepts, whose registered/operational office is KSR-VW 325/A Uthradam, Ozhakrome Kannur KERALA 670567 . "you", “your”, "user", “visitor” shall mean any natural or legal person who is visiting this website ("https://usdevsforhire.com") and/or agreed to purchase from us.</Typography>
                    </div>

                    <div className="mt-3">
                        <Typography level='title-md'>Your use of the website and/or purchase from us are governed by following Terms and Conditions:</Typography>
                        <Typography level='body-md'>
                            <ul>
                                <li>The content of the pages of this website is subject to change without notice.</li>

                                <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>

                                <li>Your use of any information or materials on our website and/or product pages is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through our website and/or product pages meet your specific requirements.</li>

                                <li>Our website contains material which is owned by or licensed to us. This material includes, but are not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>

                                <li>All trademarks reproduced in our website which are not the property of, or licensed to, the operator are acknowledged on the website.</li>

                                <li>Unauthorized use of information provided by us shall give rise to a claim for damages and/or be a criminal offense.</li>

                                <li>Our website may also include links to other websites. These links are provided for your convenience to provide further information.</li>

                                <li>Any dispute arising out of use of our website and/or purchase with us and/or any engagement with us is subject to the laws of India .</li>

                                <li>We, shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time</li>
                            </ul>
                        </Typography>
                    </div>

                </div>
            </div>
            <Footer/>
        </>
    )
}

export default TermsAndConditions;