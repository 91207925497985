import { extendTheme } from "@mui/joy";
import { createTheme } from "@mui/material";

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
});

const appTheme = extendTheme({
    fontFamily: {
        display: "Instrument Sans, Inter, sans-serif",
        body: "Instrument Sans, Inter, sans-serif"
    },
    typography: {
        "body-sm": {
            fontWeight: 500
        },
        "body-md": {
            fontWeight: 500
        },
        "body-lg": {
            fontWeight: 500
        },
        "title-md": {
            fontWeight: 800
        },
        "h1": {
            fontSize: '2rem',
            [theme.breakpoints.up("sm")]: { fontSize: '1.8rem' },
            [theme.breakpoints.up("md")]: { fontSize: '2.8rem' },
            [theme.breakpoints.up("lg")]: { fontSize: '3.2rem' },
            fontWeight: 600,
        },
        "h2": {
            fontWeight: 600
        },
    },
    components: {
        JoyButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    borderRadius: '3px',
                    ...(ownerState.color === "main" && {
                        color: 'white',
                        ":hover": {
                            backgroundColor: "var(--joy-palette-main-600)"
                        }
                    }),

                    ...((ownerState.color === "main" && ownerState.variant === "soft") && {
                        color: 'var(--joy-palette-main-800)',
                        ":hover": {
                            backgroundColor: "var(--joy-palette-main-500)"
                        }
                    }),

                    ...((ownerState.color === "main" && ownerState.variant === "outlined") && {
                        color: 'var(--joy-palette-main-800)',
                        ":hover": {
                            backgroundColor: "var(--joy-palette-main-200)"
                        }
                    }),
                })
            }
        },
    },
    colorSchemes: {
        light: {
            palette: {
                main: {
                    "100": "#DBE3D9",
                    "200": "#BAC7B5",
                    "300": "#9BAA91",
                    "400": "#7B8C6C",
                    "500": "#5C6E49",
                    "600": "#3E4E27",
                    "700": "#1F2E07",
                    "800": "#102705",
                    "900": "#052004",
                    solidBg: 'var(--joy-palette-main-700)',
                    solidActiveBg: 'var(--joy-palette-main-500)',
                    outlinedBorder: 'var(--joy-palette-main-500)',
                    outlinedColor: 'var(--joy-palette-main-700)',
                    outlinedActiveBg: 'var(--joy-palette-main-100)',
                    softColor: 'var(--joy-palette-main-800)',
                    softBg: 'var(--joy-palette-main-200)',
                    softActiveBg: 'var(--joy-palette-main-300)',
                    plainColor: 'var(--joy-palette-main-700)',
                    plainActiveBg: 'var(--joy-palette-main-100)',
                }
            }
        }
    }
})

export { appTheme }