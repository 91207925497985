import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Alert, Button, Chip, ChipDelete, CircularProgress, FormControl, FormHelperText, FormLabel, Grid, Input, Radio, RadioGroup, Sheet, Textarea, Modal, ModalDialog, Typography, List, ListItem, Checkbox, Stack, ModalClose, Autocomplete } from '@mui/joy';
import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../firebase/AuthContext';
import { ListingContext } from '../../firebase/ListingsContext';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { Add, Done, Paid } from '@mui/icons-material';
import axios from 'axios';
import DarkLoader from '../../components/DarkLoader/Darkloader';
import { toast } from 'react-toastify';
import { getAnalytics, logEvent } from 'firebase/analytics';
import FullscreenLoader from '../../components/FullscreenLoader/FullscreenLoader';
import Navbar from '../../components/Navbar/Navbar';
import { UserDataContext } from '../../firebase/UserDataProvider';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { Grow } from '@mui/material';
import { regions } from '../../data-types/USStates';
import { Icon } from '@iconify/react';
    var _ = require('lodash');
const dayjs = require('dayjs');

const AddListing = () => {

    const navigate = useNavigate()

    const analytics = getAnalytics()
    const functions = getFunctions()
    const db = getFirestore()
    const listingRef = createRef();

    const [skills, setSkills] = useState([]);
    const [links, setLinks] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [value, setValue] = useState([]);
    const [usernameModalOpen, setUsernameModalOpen] = useState(true)

    const [username, setUsername] = useState("")
    const [settingUsername, setSettingUsername] = useState(false)

    // State variables for username input
    const [startedTyping, setStartedTyping] = useState(false)
    const [available, setAvailable] = useState(false)
    const [usernameLoading, setUsernameLoading] = useState(false)

    // Data context 
    const { user, profile, userDataLoading, refreshUserData } = useContext(UserDataContext)

    const checkUsernameAvailability = _.debounce(async (username) => {
        if (username === "") { return }
        setStartedTyping(true)
        setUsernameLoading(true)
        const userdoc = await getDoc(doc(db, "listings", username))
        if (userdoc.exists()) {
            setAvailable(false)
        } else {
            setAvailable(true)
            setUsername(username)
        }
        setUsernameLoading(false)
        console.log("Checking availability for", username)
    }, 1000)

    const saveUsername = async () => {
        if (username === "") { return }
        setSettingUsername(true)
        const setProfileUsername = httpsCallable(functions, "setProfileUsername")
        try {
            await setProfileUsername({ uid: user.uid, username })
            setUsernameModalOpen(false)
            setSettingUsername(false)
        } catch (error) {
            console.log(error)
            setSettingUsername(false)
            // TODO: Add a toast function
        }
    }

    const addSkill = (e) => {
        if (e.key === "Enter") {
            e.preventDefault()
            setSkills([...skills, e.target.value])
            // console.log(e.target.value);
            e.target.value = ''
        }
    }

    const addLink = () => {
        setLinks([...links, { url: 'https://', name: "" }])
    }

    function editLinkURL(event, index) {
        var refLinks = [...links];
        var editedLink = refLinks[index];
        editedLink.url = event.target.value;
        refLinks.splice(index, 1, editedLink)
        console.log(refLinks);
        setLinks(refLinks)
    }

    function editLinkName(event, index) {
        var refLinks = [...links];
        var editedLink = refLinks[index];
        editedLink.name = event.target.value;
        refLinks.splice(index, 1, editedLink)
        setLinks(refLinks)
    }

    async function sendData(e) {
        setUploading(true)
        e.preventDefault()
        if (!e.target.elements.paypal.checked && !e.target.elements.cards.checked) {
            toast("Please select your preferred payment methods", { type: 'warning' })
            return
        }
        if (e.target.elements.hourly_rate.value === "") {
            toast("An hourly rate is required.", { type: 'warning' })
            return
        }

        const createProfile = httpsCallable(functions, "createProfile")
        try {
            await createProfile({
                firstName: e.target.elements.firstName.value,
                lastName: e.target.elements.lastName.value,
                title: e.target.elements.title.value,
                description: e.target.elements.description.value,
                email: e.target.elements.email.value,
                skills: skills,
                hourlyRate: e.target.elements.hourly_rate.value,
                workBasis: e.target.elements.work_basis.value,
                specialization: e.target.elements.specialization.value,
                links: links,
                validity: 6,
                username: profile.username ? profile.username : username,
                location: e.target.elements.location.value,
                payments: value,
                uid: user.uid,
                github: e.target.elements.github.value,
                linkedIn: e.target.elements.linkedIn.value
            })
            refreshUserData()
            logEvent(analytics, "created_profile")
            setUploading(false)
            navigate('/your-profile')
        } catch (e) {
            console.log(e);
            toast("There was an error, please try again.", { type: 'error' })
            setUploading(false)
        }


    }

    return (
        <>
            <Navbar />
            {uploading ? <DarkLoader /> : null}
            {userDataLoading
                ? <FullscreenLoader />
                : <>
                    <Modal open={profile?.username ? false : usernameModalOpen}>
                        <ModalDialog sx={{ maxWidth: '500px', width: '100%' }}>
                            <Sheet sx={{ p: 2 }}>
                                <Typography level='title-md'>Set a username</Typography>
                                <Typography level='body-sm'>Let's begin by setting a username for your account!</Typography>
                                <FormControl>
                                    <Input
                                        defaultValue={profile?.username}
                                        disabled={settingUsername}
                                        sx={{ mt: 1 }}
                                        onChange={(e) => checkUsernameAvailability(e.target.value)}
                                        autoFocus
                                        name='username'
                                        variant='soft'
                                        placeholder="Enter a username"
                                        endDecorator={usernameLoading && <CircularProgress determinate={false} size='sm' color='neutral' />} />
                                    {startedTyping
                                        ? <>
                                            {usernameLoading
                                                ? null
                                                : <>
                                                    {available
                                                        ? <FormHelperText><Typography level='body-sm' color='success'>Username is available!</Typography></FormHelperText>
                                                        : <FormHelperText><Typography level='body-sm' color='warning'>That username is taken, please try another one.</Typography></FormHelperText>}
                                                </>}
                                        </>
                                        : null}
                                </FormControl>
                                <Stack mt={1} direction='row' justifyContent='flex-end'><Button loading={settingUsername} disabled={!available} onClick={saveUsername}>Save username</Button></Stack>
                            </Sheet>
                        </ModalDialog>
                    </Modal>
                    <div className="add-listing-page">
                        <div className="container">

                            <Typography level='h2' sx={{ mt: 5 }}>Create a listing</Typography>
                            <Typography level='body-md'>Post your listing for as little as <span style={{ fontWeight: 600 }}>$39 for 6 months</span>, or for <span style={{ fontWeight: 600 }}>$59 for 12 months</span></Typography>

                            <div className="mt-3">
                                <Alert color='success'>
                                    You can create your profile for free until January 30, 2024!
                                </Alert>
                            </div>

                            <form ref={listingRef} onSubmit={sendData}>
                                <Grid container spacing={5}>
                                    <Grid xs={12} sm={6}>
                                        <div className="form-section mt-5">
                                            <Typography level='h3' sx={{ mb: 3 }}>General info</Typography>
                                            <div className="mt-3">
                                                <Stack direction='row' gap={1}>
                                                    <FormControl required sx={{ flexGrow: 1 }}>
                                                        <FormLabel>First name</FormLabel>
                                                        <Input name='firstName' placeholder='Bruce' variant='soft' />
                                                    </FormControl>
                                                    <FormControl required sx={{ flexGrow: 1 }}>
                                                        <FormLabel>Last name</FormLabel>
                                                        <Input name='lastName' placeholder='Wayne' variant='soft' />
                                                    </FormControl>
                                                </Stack>
                                            </div>

                                            <div className="mt-3">
                                                <FormControl required>
                                                    <FormLabel>Email address</FormLabel>
                                                    <Input name='email' type='email' defaultValue={profile?.email} placeholder='brucewayne@gotham.com' variant='soft' />
                                                </FormControl>
                                            </div>

                                            <div className="mt-3">
                                                <FormControl required>
                                                    <FormLabel>Job title</FormLabel>
                                                    <Input name='title' placeholder='Senior React Developer' variant='soft' />
                                                    <FormHelperText>A title for your profile that highlights your expertise.</FormHelperText>
                                                </FormControl>
                                            </div>


                                            <div className="mt-3">
                                                <FormControl>
                                                    <FormLabel>Hourly rate (in USD)</FormLabel>
                                                    <Input name='hourly_rate' type='number' startDecorator={<Paid />} placeholder="Enter an amount" variant='soft' />
                                                    {/* <FormHelperText>If you don't have an hourly rate, just enter any .</FormHelperText> */}
                                                </FormControl>
                                            </div>

                                            <div className="mt-3">
                                                <FormControl>
                                                    <FormLabel>Your location</FormLabel>
                                                    <Autocomplete name='location' placeholder="Ontario, Canada" variant='soft' options={regions} />
                                                </FormControl>
                                            </div>

                                            <div className="mt-3">
                                                <FormControl required>
                                                    <FormLabel>Basis of work</FormLabel>
                                                    <RadioGroup name='work_basis' defaultValue={"fulltime"}>
                                                        <Radio value={"fulltime"} label="Full-time" size="md" />
                                                        <Radio value={"parttime"} label="Part-time" size="md" />
                                                        <Radio value={"freelancer"} label="Freelancer" size="md" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </div>

                                            <div className="mt-3">
                                                <FormControl required>
                                                    <FormLabel>Preferred payment methods</FormLabel>
                                                    <div role="group" aria-labelledby="rank">
                                                        <List
                                                            orientation="horizontal"
                                                            wrap
                                                            sx={{
                                                                '--List-gap': '8px',
                                                                '--ListItem-radius': '20px',
                                                                '--ListItem-minHeight': '32px',
                                                            }}
                                                        >
                                                            {['PayPal', 'Credit/Debit cards'].map(
                                                                (item, index) => (
                                                                    <ListItem key={item}>
                                                                        {value.includes(item) && (
                                                                            <Done
                                                                                fontSize="md"
                                                                                color="primary"
                                                                                sx={{ ml: -0.5, mr: 0.5, zIndex: 2, pointerEvents: 'none' }}
                                                                            />
                                                                        )}

                                                                        <Checkbox
                                                                            name={item == "PayPal" ? 'paypal' : 'cards'}
                                                                            required={false}
                                                                            size="sm"
                                                                            disableIcon
                                                                            overlay
                                                                            label={item}
                                                                            checked={value.includes(item)}
                                                                            variant={value.includes(item) ? 'soft' : 'outlined'}
                                                                            onChange={(event) => {
                                                                                if (event.target.checked) {
                                                                                    setValue((val) => [...val, item]);
                                                                                } else {
                                                                                    setValue((val) => val.filter((text) => text !== item));
                                                                                }
                                                                            }}
                                                                            slotProps={{
                                                                                action: ({ checked }) => ({
                                                                                    sx: checked
                                                                                        ? {
                                                                                            border: '1px solid',
                                                                                            borderColor: 'primary.500',
                                                                                        }
                                                                                        : {},
                                                                                }),
                                                                            }}
                                                                        />
                                                                    </ListItem>
                                                                ),
                                                            )}
                                                        </List></div>
                                                    <FormHelperText>Select your preferred methods.</FormHelperText>
                                                </FormControl>
                                            </div>
                                        </div>

                                        <div className="form-section mt-5">
                                            <Typography level='h3' sx={{ mb: 3 }}>Skills and specialization</Typography>
                                            <div className="mt-3">
                                                <FormControl>
                                                    <FormLabel>Skills (add up to 5)</FormLabel>
                                                    <Input disabled={skills.length == 5} onKeyDown={addSkill} placeholder='Type in a skill and press Enter to add' variant='soft'  />
                                                    <div className='mt-3' style={{ display: 'flex', gap: 10 }}>
                                                        {skills.map((skill, index) => {
                                                            return <Chip key={index} color='warning' variant='outlined' size='lg' endDecorator={<ChipDelete onDelete={() => {
                                                                const updatedSkills = [...skills]
                                                                updatedSkills.splice(index, 1);
                                                                setSkills(updatedSkills)
                                                            }} />}>{skill}</Chip>
                                                        })}
                                                        {/* <Chip color='warning' variant='outlined' size='lg' endDecorator={<ChipDelete />}>React.JS</Chip>
                                                        <Chip color='warning' variant='outlined' size='lg' endDecorator={<ChipDelete />}>Firebase</Chip> */}
                                                    </div>
                                                </FormControl>
                                            </div>

                                            <div className="mt-3">
                                                <FormControl required>
                                                    <FormLabel>What do you specialize in?</FormLabel>
                                                    <Input name='specialization' placeholder='Dashboards, marketplaces, APIs etc.' variant='soft'  />
                                                </FormControl>
                                            </div>

                                            <div className="mt-3">
                                                <FormControl required>
                                                    <FormLabel>Describe your offer</FormLabel>
                                                    <Textarea name='description' minRows={5} placeholder='I am a developer with over 5 years of experience, and I’ve worked with...' variant='soft'  />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </Grid>

                                    <Grid xs={12} sm={6}>
                                        <div className="form-section mt-5">
                                            <Typography level='h3' sx={{ mb: 1 }}>References and links</Typography>
                                            <Typography level='body-sm'>A maximum of 4 links can be added</Typography>
                                            {(links.length !== 0)
                                                ? <div className="mt-3">

                                                    <AccordionGroup sx={{ borderRadius: '5px' }} transition="0.2s ease" variant='outlined'>
                                                        {links.map((link, index) => {
                                                            return <Accordion sx={{ borderRadius: '5px' }} defaultExpanded key={index}>
                                                                <AccordionSummary sx={({ padding: '10px' })}>{link.name ? link.name : "Link"}</AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Sheet sx={({ padding: '20px' })}>
                                                                        <FormControl>
                                                                            <FormLabel>Link to insert</FormLabel>
                                                                            <Input placeholder='https://' value={link.url} onChange={(e) => editLinkURL(e, index)} variant='soft' size='md' />
                                                                        </FormControl>
                                                                        <div className="my-3"></div>
                                                                        <FormControl>
                                                                            <FormLabel>Link title</FormLabel>
                                                                            <Input placeholder='eg. Link to my resume' value={link.name} onChange={(e) => editLinkName(e, index)} variant='soft' size='md' />
                                                                        </FormControl>
                                                                        <div className="mt-3"><Button onClick={() => {
                                                                            let updatedLinks = [...links];
                                                                            updatedLinks.splice(index, 1);
                                                                            setLinks(updatedLinks);
                                                                        }} color='danger' variant='soft'>Remove link</Button></div>
                                                                    </Sheet>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        })}
                                                    </AccordionGroup>
                                                </div>
                                                : null}
                                            <div className="mt-3" style={{ display: 'flex', gap: 10 }}>
                                                <Button onClick={addLink} disabled={links.length == 4} color='neutral' variant='outlined' startDecorator={<Add />}>Add a link</Button>
                                            </div>

                                            <div className="mt-5">
                                                    <Stack gap={2}>
                                                        <FormControl>
                                                            <FormLabel>Link to LinkedIn profile</FormLabel>
                                                            <Input name='linkedIn' startDecorator={<Icon fontSize={'24px'} icon='mdi:linkedin' />} placeholder='Enter the link to your LinkedIn profile' variant='soft' />
                                                        </FormControl>
                                                        <FormControl>
                                                            <FormLabel>Link to GitHub profile</FormLabel>
                                                            <Input name='github' startDecorator={<Icon fontSize={'24px'} icon='mdi:github' />} placeholder='Enter the link to your GitHub profile' variant='soft' />
                                                        </FormControl>
                                                    </Stack>
                                                </div>

                                            <div className="mt-5">
                                                <Button type='submit' color='main' variant='solid' loading={uploading}>Create your profile</Button>
                                            </div>

                                        </div>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </div>
                </>}
        </>
    )
}

export default AddListing;