import React from 'react';
import Spinner from '../Spinner/Spinner';

const DarkLoader = () => {
    return(
        <div style={{height: '100vh', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.6)', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, left: 0, zIndex: 100}}>
            <Spinner size={30} />
        </div>
    )
}

export default DarkLoader;