import { Link, Sheet, Stack, Typography } from '@mui/joy';
import React from 'react';

const Footer = () => {
    return (
        <>
            <Sheet color='main' variant='solid' sx={{ p: 1, mt: 4 }}>
                <Stack
                    gap={2}
                    direction='row'
                    justifyContent='center'
                    alignItems='center'>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)' }} level='body-sm'>&copy; 2024 Ceranova Concepts LLP&nbsp;|&nbsp;<Link target="__blank" rel='noopener' href="/privacy-policy">Privacy Policy</Link>&nbsp;|&nbsp;<Link target="__blank" rel='noopener' href="/terms-and-conditions">Terms and Conditions</Link>&nbsp;|&nbsp;<Link target="__blank" rel='noopener' href="/refund-and-cancellation-policy">Refund and Cancellation Policy</Link></Typography>
                </Stack>
            </Sheet>
        </>
    )
}

export default Footer