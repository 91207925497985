import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Alert, Button, Chip, ChipDelete, CircularProgress, FormControl, FormHelperText, FormLabel, Grid, Input, Radio, RadioGroup, Sheet, Textarea, Modal, ModalDialog, Typography, List, ListItem, Checkbox, Stack, Autocomplete } from '@mui/joy';
import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import { AuthContext } from '../../firebase/AuthContext';
import { ListingContext } from '../../firebase/ListingsContext';
import { Navigate, useNavigate } from 'react-router-dom';
import { Done, Paid } from '@mui/icons-material';
import axios from 'axios';
import DarkLoader from '../../components/DarkLoader/Darkloader';
import { toast } from 'react-toastify';
import Navbar from '../../components/Navbar/Navbar';
import { UserDataContext } from '../../firebase/UserDataProvider';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { Icon } from '@iconify/react';
import { regions } from '../../data-types/USStates';

const EditListing = () => {

    const functions = getFunctions()

    const { user, profile, userDataLoading, refreshUserData } = useContext(UserDataContext);
    const navigate = useNavigate()

    const [uploading, setUploading] = useState(false);
    const [value, setValue] = useState([]);

    //Form fields
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [title, setTitle] = useState(null);
    const [hourly_rate, setHourlyRate] = useState(null);
    const [location, setLocation] = useState(null);
    const [work_basis, setWorkBasis] = useState(null);
    const [payments, setPayments] = useState([]);
    const [skills, setSkills] = useState([]);
    const [specialization, setSpecialization] = useState(null);
    const [description, setDescription] = useState(null);
    const [links, setLinks] = useState([]);
    const [gitHub, setGithub] = useState("")
    const [linkedIn, setLinkedin] = useState("")

    useEffect(() => {
        if (user) {
            if (profile?.publicProfile) {
                // console.log("User has a listing");
                setFirstName(profile.publicProfile.firstName)
                setLastName(profile.publicProfile.lastName)
                setTitle(profile.publicProfile.title)
                setHourlyRate(profile.publicProfile.hourlyRate)
                setLocation(profile.publicProfile.location)
                setWorkBasis(profile.publicProfile.workBasis)
                setPayments(profile.publicProfile.payments)
                setSkills(profile.publicProfile.skills)
                setSpecialization(profile.publicProfile.specialization)
                setDescription(profile.publicProfile.description)
                setLinks(profile.publicProfile.links)
                setGithub(profile.publicProfile.github)
                setLinkedin(profile.publicProfile.linkedIn)
            }

            if (!profile?.publicProfile) {
                navigate('/add-listing')
            }
        } else {
            navigate('/login')
        }
    }, [profile])


    const addSkill = (e) => {
        if (e.key === "Enter") {
            e.preventDefault()
            setSkills([...skills, e.target.value])
            e.target.value = ''
        }
    }

    const addLink = () => {
        setLinks([...links, { url: 'https://', name: "" }])
    }

    function editLinkURL(event, index) {
        var refLinks = [...links];
        var editedLink = refLinks[index];
        editedLink.url = event.target.value;
        refLinks.splice(index, 1, editedLink)
        console.log(refLinks);
        setLinks(refLinks)
    }

    function editLinkName(event, index) {
        var refLinks = [...links];
        var editedLink = refLinks[index];
        editedLink.name = event.target.value;
        refLinks.splice(index, 1, editedLink)
        setLinks(refLinks)
    }

    async function sendData(e) {
        setUploading(true)
        e.preventDefault()
        if (!e.target.elements.paypal.checked && !e.target.elements.cards.checked) {
            toast("Please select your preferred payment methods", { type: 'warning' })
            return
        }
        const editProfile = httpsCallable(functions, "editProfile")

        try {
            const result = await editProfile({
                firstName: e.target.elements.firstName.value,
                lastName: e.target.elements.lastName.value,
                title: e.target.elements.title.value,
                description: e.target.elements.description.value,
                skills: skills,
                hourlyRate: e.target.elements.hourly_rate.value,
                workBasis: e.target.elements.work_basis.value,
                specialization: e.target.elements.specialization.value,
                links: links,
                location: e.target.elements.location.value,
                payments: payments,
                github: e.target.elements.github.value,
                linkedIn: e.target.elements.linkedin.value,
                uid: user.uid
            })
            refreshUserData()
            setUploading(false)
            navigate('/your-profile')
        } catch (e) {
            console.log(e)
            toast("There was an error, please try again.", { type: 'error' })
            setUploading(false)
        }


    }

    return (
        <>
            <Navbar />
            {uploading ? <DarkLoader /> : null}
            {userDataLoading
                ? <CircularProgress determinate={false} value={25} color='neutral' size='md' />
                : <>
                    {user
                        ? <div className="add-listing-page">
                            <div className="container">

                                <Typography level='h2' sx={{ mt: 6 }}>Edit your listing</Typography>
                                <Typography level='body-md'>Post your listing for as little as <span style={{ fontWeight: 600 }}>$39 for 6 months</span>, or for <span style={{ fontWeight: 600 }}>$59 for 12 months</span></Typography>

                                <form name='edit-listing' onSubmit={sendData}>
                                    <Grid container spacing={5}>
                                        <Grid xs={12} sm={6}>
                                            <div className="form-section mt-5">
                                                <Typography level='h4'>General info</Typography>

                                                <Stack gap={2}>
                                                    <Stack direction='row' gap={1}>
                                                        <FormControl required sx={{ flexGrow: 1 }}>
                                                            <FormLabel>First name</FormLabel>
                                                            <Input name='firstName' value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder='Bruce' variant='soft' />
                                                        </FormControl>
                                                        <FormControl required sx={{ flexGrow: 1 }}>
                                                            <FormLabel>Last name</FormLabel>
                                                            <Input name='lastName' value={lastName} onChange={(e) => setLastName(e.target.value)} placeholder='Wayne' variant='soft' />
                                                        </FormControl>
                                                    </Stack>

                                                    <FormControl>
                                                        <FormLabel>Email address</FormLabel>
                                                        <Input name='email' value={profile?.publicProfile.email} disabled placeholder='joe@deluca.com' variant='soft' />
                                                    </FormControl>

                                                    <FormControl required>
                                                        <FormLabel>Job title</FormLabel>
                                                        <Input name='title' value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Senior React Developer' variant='soft' />
                                                        <FormHelperText>Precisely mention your skill here. This will be the title of your listing.</FormHelperText>
                                                    </FormControl>

                                                    <FormControl>
                                                        <FormLabel>Hourly rate (optional)</FormLabel>
                                                        <Input name='hourly_rate' value={hourly_rate} onChange={(e) => setHourlyRate(e.target.value)} startDecorator={<Paid />} placeholder="Enter an amount" variant='soft' />
                                                        <FormHelperText>Amount in USD.</FormHelperText>
                                                    </FormControl>

                                                    <FormControl>
                                                        <FormLabel>Your location</FormLabel>
                                                        <Autocomplete name='location' options={regions} value={location} onChange={(e, newVal) => setLocation(newVal)} placeholder="Ontario, Canada" variant='soft'  />
                                                    </FormControl>

                                                    <FormControl required>
                                                        <FormLabel>Basis of work</FormLabel>
                                                        <RadioGroup name='work_basis' value={work_basis} onChange={(e) => setWorkBasis(e.target.value)} defaultValue={"fulltime"}>
                                                            <Radio value={"fulltime"} label="Full-time" size="md" />
                                                            <Radio value={"parttime"} label="Part-time" size="md" />
                                                            <Radio value={"freelancer"} label="Freelancer" size="md" />
                                                        </RadioGroup>
                                                    </FormControl>

                                                    <FormControl required>
                                                        <FormLabel>Preferred payment methods</FormLabel>
                                                        <div role="group" aria-labelledby="rank">
                                                            <List
                                                                orientation="horizontal"
                                                                wrap
                                                                sx={{
                                                                    '--List-gap': '8px',
                                                                    '--ListItem-radius': '20px',
                                                                    '--ListItem-minHeight': '32px',
                                                                }}
                                                            >
                                                                {['PayPal', 'Credit/Debit cards'].map(
                                                                    (item, index) => (
                                                                        <ListItem key={index}>
                                                                            {payments.includes(item) && (
                                                                                <Done
                                                                                    fontSize="md"
                                                                                    color="primary"
                                                                                    sx={{ ml: -0.5, mr: 0.5, zIndex: 2, pointerEvents: 'none' }}
                                                                                />
                                                                            )}

                                                                            <Checkbox
                                                                                name={item == "PayPal" ? 'paypal' : 'cards'}
                                                                                required={false}
                                                                                size="sm"
                                                                                disableIcon
                                                                                overlay
                                                                                label={item}
                                                                                checked={payments.includes(item)}
                                                                                variant={payments.includes(item) ? 'soft' : 'outlined'}
                                                                                onChange={(event) => {
                                                                                    if (event.target.checked) {
                                                                                        setPayments((val) => [...val, item]);
                                                                                    } else {
                                                                                        setPayments((val) => val.filter((text) => text !== item));
                                                                                    }
                                                                                }}
                                                                                slotProps={{
                                                                                    action: ({ checked }) => ({
                                                                                        sx: checked
                                                                                            ? {
                                                                                                border: '1px solid',
                                                                                                borderColor: 'primary.500',
                                                                                            }
                                                                                            : {},
                                                                                    }),
                                                                                }}
                                                                            />
                                                                        </ListItem>
                                                                    ),
                                                                )}
                                                            </List></div>
                                                        <FormHelperText>Select your preferred methods.</FormHelperText>
                                                    </FormControl>
                                                </Stack>
                                            </div>

                                            <div className="form-section mt-5">
                                                <Typography level='h4'>Skills and specialization</Typography>
                                                <Stack gap={2}>
                                                    <FormControl>
                                                        <FormLabel>Skills (add up to 5)</FormLabel>
                                                        <Input disabled={skills.length == 5} onKeyDown={addSkill} placeholder='Type in a skill and press Enter to add' variant='soft' />
                                                        <div className='mt-3' style={{ display: 'flex', gap: 10 }}>
                                                            {skills.map((skill, index) => {
                                                                return <Chip key={index} color='warning' variant='outlined' size='lg' endDecorator={<ChipDelete onDelete={() => {
                                                                    const updatedSkills = [...skills]
                                                                    updatedSkills.splice(index, 1);
                                                                    setSkills(updatedSkills)
                                                                }} />}>{skill}</Chip>
                                                            })}
                                                        </div>
                                                    </FormControl>

                                                    <FormControl required>
                                                        <FormLabel>What do you specialize in?</FormLabel>
                                                        <Input name='specialization' value={specialization} onChange={(e) => setSpecialization(e.target.value)} placeholder='Dashboards, marketplaces, APIs etc.' variant='soft' />
                                                    </FormControl>

                                                    <FormControl required>
                                                        <FormLabel>Describe your offer</FormLabel>
                                                        <Textarea name='description' value={description} onChange={(e) => setDescription(e.target.value)} minRows={5} sx={{ whiteSpace: 'pre' }} placeholder='I am a developer with over 5 years of experience, and I’ve worked with...' variant='soft' />
                                                    </FormControl>
                                                </Stack>
                                            </div>
                                        </Grid>

                                        <Grid xs={12} sm={6}>
                                            <div className="form-section mt-5">
                                                <Typography level='h4'>References and links</Typography>
                                                <Typography level='body-md'>A maximum of 4 links can be added</Typography>
                                                <div className="mt-3">

                                                    <AccordionGroup transition="0.2s ease" variant='outlined'>
                                                        {links?.map((link, index) => {
                                                            return <Accordion onChange={(e) => e.preventDefault()} defaultExpanded key={index}>
                                                                <AccordionSummary sx={({ padding: '10px' })}>{link.name ? link.name : "Link"}</AccordionSummary>
                                                                <AccordionDetails>
                                                                    <Sheet sx={({ padding: '20px' })}>
                                                                        <FormControl>
                                                                            <FormLabel>Link to insert</FormLabel>
                                                                            <Input placeholder='https://' value={link.url} onChange={(e) => editLinkURL(e, index)} variant='soft' size='md' />
                                                                        </FormControl>
                                                                        <div className="my-3"></div>
                                                                        <FormControl>
                                                                            <FormLabel>Link title</FormLabel>
                                                                            <Input placeholder='eg. Link to my resume' value={link.name} onChange={(e) => editLinkName(e, index)} variant='soft' size='md' />
                                                                        </FormControl>
                                                                        <div className="mt-3"><Button onClick={() => {
                                                                            let updatedLinks = [...links];
                                                                            updatedLinks.splice(index, 1);
                                                                            setLinks(updatedLinks);
                                                                        }} color='danger' variant='soft'>Remove link</Button></div>
                                                                    </Sheet>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        })}
                                                    </AccordionGroup>
                                                </div>
                                                <div className="mt-3" style={{ display: 'flex', gap: 10 }}>
                                                    <Button onClick={addLink} disabled={links.length == 4} variant='soft' color='main'>Add a link</Button>
                                                </div>

                                                <div className="mt-5">
                                                    <Stack gap={2}>
                                                        <FormControl>
                                                            <FormLabel>Link to LinkedIn profile</FormLabel>
                                                            <Input name='linkedin' startDecorator={<Icon fontSize={'24px'} icon='mdi:linkedin' />} value={linkedIn} onChange={(e) => setLinkedin(e.target.value)} placeholder='Enter the link to your LinkedIn profile' variant='soft' />
                                                        </FormControl>
                                                        <FormControl>
                                                            <FormLabel>Link to GitHub profile</FormLabel>
                                                            <Input name='github' startDecorator={<Icon fontSize={'24px'} icon='mdi:github' />} value={gitHub} onChange={(e) => setGithub(e.target.value)} placeholder='Enter the link to your GitHub profile' variant='soft' />
                                                        </FormControl>
                                                    </Stack>
                                                </div>

                                                <div className="mt-5">
                                                    <Button type='submit' color='main' variant='solid'>Save changes</Button>
                                                </div>

                                            </div>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                        </div>
                        : <Navigate to='/login' />}
                </>}
        </>
    )
}

export default EditListing;