import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { createContext, useEffect, useState } from 'react';

const UserDataContext = createContext(null)

const UserDataProvider = ({ children }) => {

    const [user, setUser] = useState(null)   // State variable to hold current user
    const [userDataLoading, setLoading] = useState(true) // State variable to hold loading state
    const [profile, setProfile] = useState(null) 

    const functions = getFunctions()
    const auth = getAuth()

    const getData = async () => {
        onAuthStateChanged(auth, async user => {
            setLoading(true)
            if (user) {
                setUser(user)
                const getUserProfile = httpsCallable(functions, "getUserProfile")
                try {
                    const response = await getUserProfile({ uid: user.uid })
                    console.log("User's profile", response.data.userProfile)
                    setProfile(response.data.userProfile)
                    setLoading(false)
                } catch (e) {
                    setLoading(false)
                    console.log("Error in UserDataProvider, ", e)
                }
            }
        })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <UserDataContext.Provider value={{ user, profile, userDataLoading, refreshUserData: getData }}>
                {children}
            </UserDataContext.Provider>
        </>
    )
}

export default UserDataProvider;
export { UserDataContext }