import React, { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { Button, Grid, Option, Select, Sheet, Stack, Switch, Typography } from '@mui/joy';
import { ArrowRightAltRounded } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import Footer from '../../ui/Footer';

const ApplyAsDev = () => {

    const auth = getAuth()
    const functions = getFunctions()
    const provider = new GoogleAuthProvider();
    const navigate = useNavigate();

    const [authLoading, setLoading] = useState(false);

    const loginWithGoogle = async () => {
        setLoading(true)
        const createUserDoc = httpsCallable(functions, "createNewUser")
        try {
            const userCred = await signInWithPopup(auth, provider);
            const resp = await createUserDoc({
                uid: userCred.user.uid,
                email: userCred.user.email,
                displayName: userCred.user.displayName
            })
            console.log(resp.data)
            setLoading(false)
            navigate('/create-profile')
        } catch (e) {
            setLoading(false)
            console.error(e);
        }
    }

    return (
        <>
            <Navbar />
            <section>
                <div className="container" style={{ textAlign: 'center' }}>
                    <Typography level='h1' sx={{ display: { xs: 'none', md: 'block' }, mt: 6, ml: 'auto', mr: 'auto', maxWidth: '800px' }}>Maximise exposure to potential clients at a minimal cost</Typography>
                    <Typography level='h1' sx={{ display: { xs: 'block', md: 'none' }, mt: 6 }}>Maximise exposure to potential clients at a minimal cost</Typography>
                    <Typography level='body-md'>Get your skills out there and let the clients hire you!</Typography>
                    <Stack direction='row' justifyContent='center' gap={1} sx={{ mt: 1 }}>
                        <Button color='main' loading={authLoading} onClick={loginWithGoogle}>Apply now!</Button>
                        <a href="#info"><Button variant='plain' color='neutral' endDecorator={<ArrowRightAltRounded />}>Learn more</Button></a>
                    </Stack>
                </div>
            </section>

            <section>
                <Sheet sx={{ width: '100%', height: '400px', mt: 6 }}>
                    <img src="https://images.unsplash.com/photo-1580894908361-967195033215?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="" />
                </Sheet>
            </section>

            <section id="info">
                <div className="container" style={{ textAlign: 'center' }}>
                    <Typography level='h2' mt={6} sx={{ maxWidth: '500px', ml: 'auto', mr: 'auto' }}>Reach small businesses looking to hire US-based developers</Typography>
                    <Typography level='body-md' sx={{ maxWidth: '400px', ml: 'auto', mr: 'auto' }}>Create a developer profile on US Devs For Hire starting at just $39 for 6 months</Typography>
                    <Stack direction='row' gap={1.5} sx={{ ml: 'auto', mr: 'auto', mb: 5, mt: 3 }} justifyContent='center'>
                        <Sheet variant='outlined' sx={{ px: 2, py: 3, borderRadius: '5px', flexGrow: 1, maxWidth: '300px' }}>
                            <Stack justifyContent='center' alignItems='center'>
                                <Typography level='h1'>$39</Typography>
                                <Typography level='body-md'>for 6 months</Typography>
                            </Stack>
                        </Sheet>

                        <Sheet variant='outlined' sx={{ px: 2, py: 3, borderRadius: '5px', flexGrow: 1, maxWidth: '300px' }}>
                            <Stack justifyContent='center'>
                                <Typography level='h1'>$59</Typography>
                                <Typography level='body-md'>for 12 months</Typography>
                            </Stack>
                        </Sheet>
                    </Stack>
                </div>
            </section>

            <section className='mb-5'>
                <div className="container">
                    <div style={{ padding: '3px 10px', backgroundColor: '#E8FFC2', borderRadius: '3px', width: 'auto', display: 'inline-flex' }}>
                        <Typography sx={{ color: '#304311' }} level='body-xs'>The process</Typography>
                    </div>
                    <Typography level='h2' mt={1}>How it works</Typography>
                    <Typography level='body-md'>No confusing processes, it’s all straightforward.</Typography>

                    <Grid container spacing={3} mt={2}>
                        <Grid xs={12} md={4}>
                            <Stack direction='column' gap={1}>
                                <div style={{ height: '40px', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#DEE5EE', borderRadius: '50px' }}><Typography level='body-lg'>1</Typography></div>
                                <Typography level='title-lg'>Sign up with Google</Typography>
                                <Typography level='body-md'>Getting started is a breeze! Sign up with your Google account, and you're well on your way to showcasing your skills.</Typography>
                            </Stack>
                        </Grid>

                        <Grid xs={12} md={4}>
                            <Stack direction='column' gap={1}>
                                <div style={{ height: '40px', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#DEE5EE', borderRadius: '50px' }}><Typography level='body-lg'>2</Typography></div>
                                <Typography level='title-lg'>Complete your developer profile</Typography>
                                <Typography level='body-md'>This is where you shine. Share your skills, experience, and a brief description to make your profile stand out. Let's make you look awesome!</Typography>
                            </Stack>
                        </Grid>

                        <Grid xs={12} md={4}>
                            <Stack direction='column' gap={1}>
                                <div style={{ height: '40px', width: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#DEE5EE', borderRadius: '50px' }}><Typography level='body-lg'>3</Typography></div>
                                <Typography level='title-lg'>List your profile</Typography>
                                <Typography level='body-md'>Ready to get noticed? Complete the payment to list your profile on our platform. That's it! You're officially part of our thriving community of developers.</Typography>
                            </Stack>
                        </Grid>
                    </Grid>

                    <Stack alignItems='center' justifyContent='center'>
                        <Button color='main' loading={authLoading} onClick={loginWithGoogle} size='lg' sx={{ marginTop: 3 }}>Apply now!</Button>
                    </Stack>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ApplyAsDev;