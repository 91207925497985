import { CssVarsProvider } from "@mui/joy";
import './firebase/init';

//Styles 
import './styles/reset.css';
import './styles/variables.css';
import './styles/typography.css';
import './styles/margins.css';
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { ListingsProvider } from "./firebase/ListingsContext";
import AddListing from "./routes/AddListing/AddListing";
import { AuthProvider } from "./firebase/AuthContext";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Profiles from "./routes/Profiles/Profiles";
import YourProfile from "./routes/YourProfile/YourProfile";
import EditListing from "./routes/AddListing/EditListing";
import ProtectedRoute from "./firebase/ProtectedRoute";
import PrivacyPolicy from "./routes/Legal/PrivacyPolicy";
import { appTheme } from "./theme/AppTheme";
import Support from "./routes/Support/Support";
import ApplyAsDev from "./routes/ApplyDeveloper/ApplyAsDev";
import Onboarding from "./routes/Onboarding/Onboarding";
import UserDataProvider from "./firebase/UserDataProvider";
import ViewProfile from "./routes/Profiles/ViewProfile";
import AlertProvider from "./ui/AlertProvider";
import TermsAndConditions from "./routes/Legal/TermsAndConditions";
import RefundPolicy from "./routes/Legal/RefundPolicy";

function App() {

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Profiles />
    },
    {
      path: '/developers',
      element: <Navigate to='/' />
    },
    // {
    //   path: '/new-developers',
    //   element: <ViewProfiles />
    // },
    {
      path: '/create-profile',
      element: <ProtectedRoute fallback={<Navigate to='/login' />}><AddListing /></ProtectedRoute>
    },
    {
      path: '/edit-profile',
      element: <ProtectedRoute fallback={<Navigate to='/login' />}><EditListing /></ProtectedRoute>
    },
    {
      path: '/your-profile',
      element: <ProtectedRoute fallback={<Navigate to='/login' />}><YourProfile /></ProtectedRoute>
    },
    {
      path: '/profile/:username',
      element: <ViewProfile />
      // element: <ViewListing />
    },
    {
      path: '/apply-as-a-developer',
      element: <ApplyAsDev />
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />
    },
    {
      path: '/refund-and-cancellation-policy',
      element: <RefundPolicy />
    },
    {
      path: '/terms-and-conditions',
      element: <TermsAndConditions />
    },
    {
      path: '/support',
      element: <Support />
    },
    {
      path: '*',
      element: <Navigate to='/' />
    }
  ])

  return (
    <>
      <div className="App">

        <AlertProvider>
          <UserDataProvider>
            <AuthProvider>
              <ListingsProvider>
                <CssVarsProvider theme={appTheme}><RouterProvider router={router} /></CssVarsProvider>
              </ListingsProvider>
            </AuthProvider>
          </UserDataProvider>
          <ToastContainer
            position='bottom-center'
            autoClose={3000}
            hideProgressBar />
        </AlertProvider>
      </div></>
  );
}

export default App;
