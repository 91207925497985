import React, { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import FullscreenLoader from '../components/FullscreenLoader/FullscreenLoader';

const ProtectedRoute = ({ children, fallback }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    const auth = getAuth()
    onAuthStateChanged(auth, user => {
      // This function is called whenever the user's authentication state changes
      if (user) {
        setIsLoggedIn(true); // User is authenticated
      } else {
        setIsLoggedIn(false); // User is not authenticated
      }
      setLoading(false); // Notify the parent component that loading is complete
    });
  }, [loading]);

  return (
      <>
      { loading ? <FullscreenLoader /> : <>{isLoggedIn ? children : fallback}</> }
        
      </>
  );
};

export default ProtectedRoute;