import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { Avatar, Button, Chip, IconButton, Sheet, Skeleton, Stack, Typography, useTheme } from '@mui/joy';
import { Icon } from '@iconify/react';
import { useMediaQuery } from '@mui/material';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useParams } from 'react-router-dom';
import { useAlert } from '../../ui/AlertProvider';

const ViewProfile = () => {

    const theme = useTheme()
    const functions = getFunctions()
    const getUserProfileByUsername = httpsCallable(functions, "getUserProfileByUsername")
    const { username } = useParams()

    const [profile, setProfile] = useState(null);
    const [profileLoading, setProfileLoading] = useState(true);
    const [notFound, setNotFound] = useState(false)

    const createAlert = useAlert()

    const getProfile = async () => {
        setProfileLoading(true)
        try {
            const userProfile = await getUserProfileByUsername({ username })
            setProfile(userProfile.data.userProfile)
            setProfileLoading(false)
        } catch (e) {
            if (e.code === "functions/not-found") { console.log("Profile not found") }
            setProfileLoading(false)
        }
    }

    const copyEmail = async () => { navigator.clipboard.writeText(profile.publicProfile.email); createAlert({ type: 'primary', message: "Email address copied to clipboard!" }) }

    useEffect(() => {
        getProfile()
    }, [])

    return (
        <>
            <Navbar />
            <div className="container">
                <Stack
                    direction={useMediaQuery(theme.breakpoints.up('md')) ? "row" : "column"}
                    mt={2}
                    gap={6}
                    alignItems='start'>

                    {/* Profile area */}
                    <Sheet variant='outlined' sx={{
                        py: 2,
                        px: useMediaQuery(theme.breakpoints.up('md')) ? 6 : 2,
                        borderRadius: '10px',
                        width: useMediaQuery(theme.breakpoints.up('md')) ? "initial" : "100%"
                    }}>
                        <Stack direction={useMediaQuery(theme.breakpoints.up('md')) ? "column" : "row"} alignItems='center' gap={useMediaQuery(theme.breakpoints.up('md')) ? 1 : 3}>
                            <Avatar
                                variant='soft'
                                sx={{
                                    width: useMediaQuery(theme.breakpoints.up('md')) ? "100px" : "65px",
                                    height: useMediaQuery(theme.breakpoints.up('md')) ? "100px" : "65px"
                                }}
                                src={profile?.publicProfile.photo ? profile?.publicProfile.photo : "https://firebasestorage.googleapis.com/v0/b/remote-devs-directory.appspot.com/o/profiles%2FDefault%20Profile.png?alt=media&token=a4c382f6-d5bc-4984-9efe-82418603a726"}>{profileLoading && <Skeleton loading={profileLoading} />}</Avatar>
                            <Stack gap={1}>
                                <Stack alignItems={useMediaQuery(theme.breakpoints.up('md')) ? "center" : "start"}>
                                    <Typography level='h3' sx={{ position: 'relative', overflow: 'hidden' }}>
                                        <Skeleton loading={profileLoading} />
                                        {profile ? `${profile.publicProfile.firstName} ${profile.publicProfile.lastName}` : "Loading name"}
                                    </Typography>
                                    <Typography level='title-sm' sx={{ position: 'relative', overflow: 'hidden' }}>
                                        <Skeleton loading={profileLoading} />
                                        {profile ? `${profile.publicProfile.location}` : "Ontario, Canada"}
                                    </Typography>
                                </Stack>
                                {!profileLoading && <>
                                    <Stack justifyContent='center' direction='row' gap={1}>
                                        <Button color='main' onClick={copyEmail}>Email</Button>
                                        {profile?.publicProfile.linkedIn && <a href={profile.publicProfile.linkedIn} rel='noopener' target="__blank"><IconButton variant='outlined'><Icon fontSize={'24px'} icon='mdi:linkedin' /></IconButton></a>}
                                        {profile?.publicProfile.github && <a href={profile.publicProfile.github} rel='noopener' target="__blank"><IconButton variant='outlined'><Icon fontSize={'24px'} icon='mdi:github' /></IconButton></a>}
                                    </Stack>
                                </>}
                            </Stack>
                        </Stack>
                    </Sheet>

                    {/* Offer section */}
                    <Stack>
                        <Typography
                            level='h2'
                            sx={{ mt: useMediaQuery(theme.breakpoints.up('md')) ? 4 : 0, position: 'relative', overflow: 'hidden' }}
                            endDecorator={profile?.publicProfile.workBasis === "freelancer" ? <Chip color='success'>Freelancer</Chip> :
                            profile?.publicProfile.workBasis === "fulltime" ? <Chip color='primary'>Full-time</Chip> :
                                <Chip color='danger'>Part-time</Chip>}>
                                <Skeleton loading={profileLoading} />
                                {profile ? `${profile.publicProfile.title}` : "Loading title..."}
                                </Typography>
                        <Typography
                            level='body-sm'
                        // sx={{ mt: 4 }}
                        >Specializing in: {profile?.publicProfile.specialization || "..."}</Typography>

                        <Typography level='title-md' sx={{ mt: 2 }}>Skills</Typography>
                        <Stack direction='row' gap={0.5}>
                            {profile?.publicProfile.skills.map((skill, index) => <Chip key={index} color='warning'>{skill}</Chip>)}
                        </Stack>

                        <Typography level='title-md' sx={{ mt: 2 }}>Description</Typography>
                        <Typography level='body-md' sx={{ maxWidth: '600px' }}>
                            <div dangerouslySetInnerHTML={{ __html: profile?.publicProfile.description.replace(/\n/g, '<br/>') }}></div>
                        </Typography>

                        <Typography level='title-md' sx={{ mt: 2 }}>Portfolio and Links</Typography>
                        <Stack gap={0.5} mt={1} sx={{ maxWidth: '600px' }}>
                            {profile?.publicProfile.links.map((link, index) => <a href={link.url} style={{ textDecoration: 'none' }} rel='noopener' target='__blank'>
                                <Sheet key={index} variant='outlined' color='primary' sx={{ borderRadius: '5px', p: 2, cursor: 'pointer' }}>
                                    <Stack direction='row' gap={2}>
                                        <Icon color='#004DE4' fontSize='24px' icon='ic:round-link' />
                                        <Typography color='primary' level='body-md'>{link.name}</Typography>
                                    </Stack>
                                </Sheet>
                            </a>)}
                        </Stack>

                        {!profileLoading && <>
                            <Typography level='title-md' sx={{ mt: 2 }}>Hourly rate</Typography>
                            <Typography level='h2' sx={{ mb: 4 }}>${profile?.publicProfile.hourlyRate}/hour</Typography>
                        </>}
                    </Stack>


                </Stack>
            </div>
        </>
    )
}

export default ViewProfile;