import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { collection, getDocs, getFirestore, onSnapshot, query, where } from 'firebase/firestore';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [listing, setListing] = useState(null);
    const [loading, setLoading] = useState(true);

    const getDetails = async () => {
        const auth = getAuth();
        const db = getFirestore();

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const q = query(collection(db, 'users'), where('email', '==', user.email));

                const unsubscribeFromDB = onSnapshot(q, (snapshot) => {
                    // console.log(snapshot);
                    if (snapshot.size !== 0) {
                        const doc = snapshot.docs[0].data()
                        const docWithID = Object.assign(doc, { id: snapshot.docs[0].id })
                        setListing(docWithID)
                    }
                    // console.log('From live listen: ', doc);

                })

                try {
                    const snapshot = await getDocs(q);
                    const doc = snapshot.docs[0].data()
                    // console.log(doc);
                    const docWithID = Object.assign(doc, { id: snapshot.docs[0].id })
                    setListing(docWithID)
                    setLoading(false)
                } catch (e) {
                    console.log(e);
                    setLoading(false)
                }

                setUser(user)
                setLoading(false)
            } else {
                setUser(null)
            }

            setLoading(false)

        })

        return () => {
            unsubscribe()
            setLoading(false)
        }
    }

    useEffect(() => {
        getDetails()
    }, [])


    return (
        <AuthContext.Provider value={{ user, listing, loading }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext);
}

export { AuthContext }