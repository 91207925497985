import React from 'react';
import { CircularProgress } from '@mui/joy';

const FullscreenLoader = () => {
    return(
        <div style={{height: '100vh', width: '100%', backgroundColor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, left: 0, zIndex: 100}}>
            <CircularProgress determinate={false} size='md' color='neutral' />
        </div>
    )
}

export default FullscreenLoader;