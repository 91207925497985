import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDGCRFFgM-RlTBqPyYVKV8_Hmhlg7Mf-_w",
  // authDomain: "usdevsforhire.com",
  authDomain: "remote-devs-directory.firebaseapp.com",
  projectId: "remote-devs-directory",
  storageBucket: "remote-devs-directory.appspot.com",
  messagingSenderId: "556440456919",
  appId: "1:556440456919:web:5dd59a051afa9c83d9aa92",
  measurementId: "G-6HJJWKJEQT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { app }