import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { Link, Typography } from '@mui/joy';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <title>US Devs For Hire - Privacy Policy</title>
            </Helmet>
            <Navbar />
            <div className="container">
                <div className="my-5">
                    <Typography level='h2'>Privacy Policy</Typography>
                    <Typography level='h4'>Last updated on January 8, 2024</Typography>

                    <div className="mt-3">
                        <Typography level='title-md'>1. Introduction</Typography>
                        <Typography level='body-md'>Welcome to usdevsforhire.com ("the Website"). At US Devs For Hire, we respect your privacy and are committed to protecting your personal information. This Privacy Policy is designed to help you understand how we collect, use, disclose, and safeguard your personal data. By using our Website, you consent to the practices described in this Privacy Policy. This Privacy Policy is applicable to users of usdevsforhire.com and is provided by Ceranova Concepts LLP, a company based in India. <br /> Ceranova Concepts is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, and then you can be assured that it will only be used in accordance with this privacy statement. <br />Ceranova Concepts may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are in agreement with any changes.</Typography>
                    </div>

                    <div className="mt-3">
                        <Typography level='title-md'>2. Information we collect</Typography>
                        <Typography level='body-md'>We collect the following personal information from our users during the profile creation process:</Typography>
                        <Typography level='body-md'>
                            <ul>
                                <li>Name</li>
                                <li>Email address</li>
                                <li>Location (manually provided by user)</li>
                            </ul>
                        </Typography>
                    </div>

                    <div className="mt-3">
                        <Typography level='title-md'>3. How we use your information</Typography>
                        <Typography level='body-md'>
                            <ul>
                                <li>The data we collect is used for creating and populating user profiles on our Website. These profiles are intended for marketing to potential employers or clients. </li>
                                <li>We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.</li>
                                <li>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email. We may use the information to customise the website according to your interests.</li>
                                <li>Your personal data will not be used for any other purposes without your explicit consent.</li>
                            </ul>
                        </Typography>
                        <Typography level='body-md'>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in suitable measures.</Typography>
                    </div>

                    <div className="mt-3">
                        <Typography level='title-md'>4. Cookies and Tracking</Typography>
                        <Typography level='body-md'>A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyses web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</Typography> <br />
                        <Typography level='body-md'>We use traffic log cookies to identify which pages are being used. This helps us analyses data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</Typography> <br />
                        <Typography level='body-md'>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</Typography> <br />
                        <Typography level='body-md'>We use Google Analytics and Microsoft Clarity to collect anonymized data for the purpose of improving our Website's performance and user experience. These services may use cookies or similar technologies. You can manage your cookie preferences through your browser settings.</Typography>
                    </div>

                    <div className="mt-3">
                        <Typography level='title-md'>5. Data Retention</Typography>
                        <Typography level='body-md'>We retain your data for as long as you choose to keep your profile on our platform. You can delete your profile at any time, which will result in the removal of your personal information from our Website.</Typography>
                    </div>

                    <div className="mt-3">
                        <Typography level='title-md'>6. Data Sharing</Typography>
                        <Typography level='body-md'>We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen. Your data will only be shared with potential employers or clients in the context of marketing your profile as per your preferences.</Typography>
                    </div>

                    <div className="mt-3">
                        <Typography level='title-md'>7. User Rights</Typography>
                        <Typography level='body-md'>As a user of usdevsforhire.com, you have the right to access, correct, or delete your personal data. To exercise these rights or if you have any privacy-related inquiries, please contact us at <Link href='mailto:amithchalil@gmail.com'>amithchalil@gmail.com</Link>.</Typography>
                    </div>

                    <div className="mt-3">
                        <Typography level='title-md'>8. Age Restriction</Typography>
                        <Typography level='body-md'>Our platform is intended for users aged 13 and above. We do not knowingly collect or store information from individuals under the age of 13.</Typography>
                    </div>

                    <div className="mt-3">
                        <Typography level='title-md'>9. Contact Us</Typography>
                        <Typography level='body-md'>If you have any questions or concerns about this Privacy Policy or our data practices, please contact me at <Link href='mailto:amithchalil@gmail.com'>amithchalil@gmail.com</Link></Typography>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PrivacyPolicy;