import React, { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { Button, FormControl, FormLabel, Input, Link, Textarea, Typography } from '@mui/joy';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getFunctions, httpsCallable } from 'firebase/functions';

const Support = () => {

    const [loading, setLoading] = useState(false)
    const functions = getFunctions()

    const sendMessage = async (e) => {
        setLoading(true)
        e.preventDefault()
        const newSupportRequest = httpsCallable(functions, "newSupportRequest")
        try {
            await newSupportRequest({
                name: e.target.elements.name.value,
                email: e.target.elements.email.value,
                subject: e.target.elements.subject.value,
                message: e.target.elements.message.value
            })
            setLoading(false)
            toast("Your message has been submitted!", { type: 'success' })
            logEvent(getAnalytics(), "submitted_support_request")
        } catch (e) {
            console.log("Error submitting request", e);
            toast("There was an error submitting the form", {type: 'error'})
            setLoading(false)
        }
    }

    return (
        <>
            <Navbar />
            <div className="container mt-4" style={{ textAlign: 'center', marginLeft: 'auto', marginRight: 'auto' }}>
                <Typography level='h2'>Contact support</Typography>
                <Typography level='body-sm'>Found a bug? Want to suggest a feature? Contact us at <Link href="mailto:amithchalil@gmail.com">amithchalil@gmail.com</Link> or use this form to get in touch!</Typography>
                <Typography level='title-md'>Contact address</Typography>
                <Typography level='title-sm'>325/A Uthradam, <br /> Maryangalam, Kannur, <br />Kerala, India</Typography>

                <form action="" onSubmit={sendMessage} className='mt-3' style={{ maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
                    <FormControl required sx={{ mb: 2 }}>
                        <FormLabel>Your name</FormLabel>
                        <Input type='text' name='name' placeholder='John Doe' size='md' />
                    </FormControl>

                    <FormControl required sx={{ mb: 2 }}>
                        <FormLabel>Your email address</FormLabel>
                        <Input type='email' name='email' placeholder='john.doe@example.com' size='md' />
                    </FormControl>

                    <FormControl required sx={{ mb: 2 }}>
                        <FormLabel>Subject</FormLabel>
                        <Input name='subject' placeholder="Just wanna say hi!" size='md' />
                    </FormControl>

                    <FormControl required sx={{ mb: 2 }}>
                        <FormLabel>Your message</FormLabel>
                        <Textarea minRows={5} name='message' placeholder="Hello there! It's an amazing platform to be on!" size='md' />
                    </FormControl>

                    <Button type='submit' loading={loading}>Submit message</Button>
                </form>
            </div>
        </>
    )
}

export default Support;