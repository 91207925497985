import { Modal, ModalDialog, Sheet, Stepper, Step, Typography, Tabs, TabList, TabPanel, FormControl, Input, Divider, Stack, Button, FormHelperText, CircularProgress, FormLabel, ToggleButtonGroup, Autocomplete } from '@mui/joy';
import { collection, doc, getDoc, getFirestore } from 'firebase/firestore';
import React, { createRef, useContext, useEffect, useState } from 'react';
import '../../firebase/init';
import { UserDataContext } from '../../firebase/UserDataProvider';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { regions } from '../../data-types/USStates';
var _ = require('lodash');

const Onboarding = () => {

    const db = getFirestore()
    const functions = getFunctions()

    const [username, setUsername] = useState("")
    const [currentTab, setCurrentTab] = useState(0)
    const [settingUsername, setSettingUsername] = useState(false)

    // State variables for username input
    const [startedTyping, setStartedTyping] = useState(false)
    const [available, setAvailable] = useState(false)
    const [usernameLoading, setUsernameLoading] = useState(false)

    // Data context 
    const { user, profile, userDataLoading } = useContext(UserDataContext)

    const moveData = async () => {
        const moveD = httpsCallable(functions, "moveData")
        try{
            const r = await moveD()
            console.log(r.data.message)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            <div style={{
                height: '100vh',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>

                <Modal open>
                    <ModalDialog sx={{
                        maxWidth: '600px',
                        width: '90%',
                        borderRadius: '10px'
                    }}>
                        <Sheet sx={{ p: 2 }}>
                            <Stack alignItems='center' gap={2}>
                                <Typography level='body-md'>Click here to move data</Typography>
                            <Button onClick={moveData}>Move data</Button>
                            </Stack>
                        </Sheet>
                    </ModalDialog>
                </Modal>
            </div>
        </>
    )
}

export default Onboarding;