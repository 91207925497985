import React from 'react';
import './spinner.css'; // Create a CSS file for styling

const Spinner = ({ size = 40 }) => {
    const spinnerStyle = {
        width: size,
        height: size,
        borderWidth: size / 10,
        borderColor: 'lightgrey',
        borderTopColor: 'black',
    };

    return <div className="spinner" style={spinnerStyle}></div>;
};

export default Spinner;
