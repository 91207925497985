import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import { Link, Typography } from '@mui/joy';
import { Helmet } from 'react-helmet';

const RefundPolicy = () => {
    return (
        <>
            <Helmet>
                <title>Refunds and Cancellation Policy</title>
            </Helmet>
            <Navbar />
            <div className="container">
                <div className="my-5">
                    <Typography level='h2'>Refunds and Cancellation Policy</Typography>
                    <Typography level='body-md'>Last updated on May 5, 2024</Typography>

                    <div className="mt-3">
                        <Typography level='title-md'>We are committed to your satisfaction with our service.</Typography>
                        <Typography level='body-md'>This policy outlines the terms for requesting a refund for subscriptions purchased on US Devs for Hire.</Typography>
                        <Typography level='body-md'>
                            <ul>
                                <li><Typography level='title-md'>Full Refunds: </Typography><Typography level='body-md'>You are eligible for a full refund if you request it within 30 days of purchasing your subscription.</Typography></li>
                                <li><Typography level='title-md'>No Refunds After 30 Days: </Typography><Typography level='body-md'>Unfortunately, we cannot offer refunds for subscriptions cancelled after the 30-day window.</Typography></li>
                                <li><Typography level='title-md'>No Partial Refunds: </Typography><Typography level='body-md'>We are unable to offer partial refunds for unused portions of your subscription. You will receive the benefits for the subscription till the end of the billing period even after cancellation</Typography></li>
                            </ul>
                        </Typography>
                    </div>

                    <div className="mt-3">
                        <Typography level='title-md'>How to request a refund</Typography>
                        <Typography level='body-md'>To request a refund, please contact us within 30 days of your purchase at <Link href='mailto:amithchalil@gmail.com'>amithchalil@gmail.com</Link> or <Link href='tel:+917559844308'>+91 75598 44308</Link>. <br /> We will process your refund request within 5 business days.  The refunded amount will be credited back to the original payment method used for the purchase.</Typography>
                    </div>

                    <div className="mt-3">
                        <Typography level='title-md'>Please note:</Typography>
                        <Typography level='body-md'>
                            <ul>
                                <li><Typography level='body-md'>This policy applies to all subscription plans offered on US Devs for Hire.</Typography></li>
                                <li><Typography level='body-md'>We reserve the right to modify this policy at any time. Any changes will be posted on this page.</Typography></li>
                            </ul>
                        </Typography>
                        <Typography level='body-md'>By subscribing to our service, you acknowledge that you have read and agree to this Refund Policy.</Typography>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RefundPolicy;