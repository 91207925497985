import { Chip, Sheet, Stack, Typography } from '@mui/joy';
import React from 'react';
import { Link } from 'react-router-dom';

const ProfileCard = ({ profile, index }) => {
    return (
        <Link style={{ color: 'unset', textDecoration: 'none' }} key={index} to={`/profile/${profile.publicProfile.username}`}>
            <Sheet variant='outlined' sx={{ p: 1.5, borderRadius: '5px' }}>
                <Stack direction='row' justifyContent='space-between' alignItems='start' gap={1}>
                    <Stack justifyContent='left' sx={{ textAlign: 'left' }}>
                        <Typography level='h3' endDecorator={
                            profile?.publicProfile.workBasis === "freelancer" ? <Chip color='success'>Freelancer</Chip> :
                                profile?.publicProfile.workBasis === "fulltime" ? <Chip color='primary'>Full-time</Chip> :
                                    <Chip color='danger'>Part-time</Chip>
                        }>{profile.publicProfile.title}</Typography>
                        <Typography level='body-md'>{profile.publicProfile.location}</Typography>
                    </Stack>
                    <Typography level='h3'>{`$${profile.publicProfile.hourlyRate}/hr`}</Typography>
                </Stack>
                <Stack gap={1} direction='row' flexWrap='wrap' sx={{ mt: 0.5 }}>
                    {profile.publicProfile.skills.map((skill, index) => {
                        return <Chip key={index} variant='soft' color='warning' size='md'>{skill}</Chip>
                    })}
                </Stack>
            </Sheet>
        </Link>
    )
}

export default ProfileCard;